// Here you can add other styles
.brand {
	padding-top: 50px;
	padding-right: 0px;
	padding-bottom: 50px;
	padding-left: 0px;
  }
.loader-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semitransparente */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* Asegúrate de que el z-index sea lo suficientemente alto para que el loader esté por encima de otros elementos */
  }
  .banner-image {
	height: 200px;
  }
  .ajusteCelda {
	width:34%!important;
	align-content: center;
  }
  .nombre-login-page {
	font-size: xx-large !important ;
	color: black;
	font-weight: bold;
  }

  /* Estilos para el contenedor de paginación */
.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 20px 0; /* Espaciado en la parte superior e inferior */
  }
  
  /* Estilos para los elementos de paginación */
  .pagination li {
	margin: 0 5px; /* Espacio entre los elementos de paginación */
  }
  
  /* Estilos para el enlace de la página activa */
  .pagination li a.active {
	background-color: #007bff; /* Color de fondo para la página activa */
	color: #fff; /* Color de texto para la página activa */
	border-radius: 50%; /* Hace que el botón de la página activa sea redondo */
	padding: 8px 12px; /* Espaciado dentro del botón de la página activa */
  }
  .fondo_especial_input {
	background-color: #fbf9c1
  }
  .custom-max-width {
	max-width: 400px;
  }
  .small-text {
	font-size: 8px; /* Tamaño de fuente más pequeño */
  }
  
  .modalAlert .modal-backdrop {
	opacity: 0.5; /* Ajusta la opacidad según necesites */
  }
  